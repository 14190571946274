import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import React, { useEffect, useState, useContext } from 'react';

import authApi from '../api/auth';
import AuthContext from '../auth/context';
import jwtDecode from 'jwt-decode';
import authStorage from '../auth/storage';
import { Button, Input, TextField } from '@mui/material';
import colors from '../../config/colors';
import { useSnackbar } from 'material-ui-snackbar-provider';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';



function LoginScreen({ route, navigation }) {

    const isPortrait = () => {
        const dim = Dimensions.get('screen');
        return dim.height >= dim.width;
      };

    const snackbar = useSnackbar();

    const authContext = useContext(AuthContext);

    const [secured, setSecured] = useState(true);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");



    const handleLogin = async () => {
        if ((username != "" && username != null) && (password != "" && password != null)) {
            console.log('values');
            console.log({ username, password });
            const result = await authApi.login(username, password);
            console.log(result.data);
            if (!result.ok) return snackbar.showMessage("Identifiants éronnés");
            const user = jwtDecode(result.data.token);
            authContext.setUser(result.data);
            authStorage.storeToken(result.data);
            console.log("RESULTAT");
            console.log(result.data);
            snackbar.showMessage("Connexion réussie");
        }
        else {
            snackbar.showMessage("Les champs ne peuvent pas être vides.");
        }
    }

    return (
        <View style={styles.container}>
          <Image source={require("../../assets/bg.png")} style={styles.backgroundImage}/>
          <View style={[styles.mainContainer, isPortrait() ? {width: "100%", height: "100%", justifyContent: "center", borderRadius: 0} : null]}>
            <Image source={require("../../assets/logo-greenride.png")} style={{width: "100%", height:100}} resizeMode="contain"/>
            <AppInput placeholder="Nom d'utilisateur..." value={username} onChangeText={(value)=>setUsername(value)}/>
            <AppInput onKeyPress={(e) => {
                                if (e.nativeEvent.key == "Enter") {
                                    handleLogin();
                                }}} placeholder="Mot de passe..." value={password} onChangeText={(value)=>setPassword(value)} secureTextEntry={true}/>
            <AppButton onPress={() => handleLogin()} title="CONNEXION"/>
            <View style={styles.separator}></View>
            <TouchableOpacity style={{cursor: "pointer"}} cursor="pointer" onPress={()=> Linking.openURL("http://ascenseurs-service.fr/")}>
              <Image source={require("../../assets/logo-as.png")} style={{width: "100%", height:100, marginTop: 40, marginBottom: 10}} resizeMode="contain"/>
            </TouchableOpacity>
          </View>
          <StatusBar style="auto" />
        </View>

    );
}

export default LoginScreen;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.green,
      alignItems: 'center',
      justifyContent: 'center',
    },
  
    mainContainer: {
      width: 480,
      backgroundColor: colors.black,
      padding: 30,
      borderRadius: 20
    },
  
    mainContainerFull: {
      flex: 1,
      width: "100%",
      backgroundColor: colors.black,
      padding: 30,
      borderRadius: 20,
      justifyContent: "space-between"
    },
    
    separator: {
      height: 2,
      backgroundColor: colors.lightGrey,
      width: "100%",
      marginTop: 40
    },
  
    backgroundImage: {
      position: 'absolute',
      width: "100%",
      height: "100%",
      opacity: 0.2,
      flex: 1
    },
  
    dateSelector: {
      flexDirection: "row",
      justifyContent: "center"
    },
  
    machineView: {
      borderWidth: 4,
      borderRadius: 20,
      width: "30%",
      shadowColor: colors.green,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
  
      elevation: 6,
    },
  
    machineContainer: {
      padding: 30
    },
  
    machineDatas: {
      marginTop: 20,
      textAlign: "center"
    },
  
    machineInfos: {
      borderBottomWidth: 2,
      borderBottomColor: colors.green,
      padding: 30
    },
  
    machineIcon: {
      position: "absolute",
      width: 75,
      height: 75,
      top: 20,
      right: 30
    },
  
    machineTitle: {
      fontFamily: "Montserrat Bold", 
      fontSize: 25,
      marginRight: 100,
      color: colors.lightGrey,
    },
  
    machineSubTitle: {
      fontFamily: "Montserrat", 
      fontSize: 18, 
      color: colors.lightGrey,
    },
  
    machineDatasTitle: {
      fontFamily: "Montserrat Bold", 
      fontSize: 25, 
      color: colors.lightGrey,
    },
  
    machineDatasSubTitle: {
      fontFamily: "Montserrat", 
      fontSize: 18, 
      color: colors.lightGrey,
    },
  
    screenContent: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 50,
      paddingRight: 50,
    },
    
    copyrights: {
      borderTopWidth: 2,
      borderTopColor: colors.lightGrey,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 20,
      alignSelf: "flex-end",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%"
    }
  
  
  });
  