import {StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native';
import {useFonts} from 'expo-font';

import colors from '../config/colors';

function AppInput({styleText, styleInput, ...otherProps}) {

    const [loaded] = useFonts({
        'Montserrat': require('../assets/fonts/Montserrat-Regular.ttf'),
        'Montserrat Bold': require('../assets/fonts/Montserrat-Bold.ttf'),
    });
      
    if (!loaded) {
        return null;
    }

    return (
            <TextInput placeholderTextColor={colors.grey} style={styleInput ? styleInput : styles.input} {...otherProps} />
    );
}

const styles = StyleSheet.create({
    input: {
        
        borderColor: colors.green,
        borderWidth: 2,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 15,
        paddingLeft: 15,
        width: '100%',
        color: colors.white,
        fontSize: 18,
        textTransform: 'uppercase',
        fontFamily: 'Montserrat Bold',
        marginTop: 20,
        borderRadius: 15
    },

    text: {
        textAlign: "center",
        color: colors.black,
        fontSize: 25,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: 'Montserrat Bold'
    }
})

export default AppInput;