import { create } from 'apisauce';
import authStorage from '../auth/storage';

const apiClient = create({
    baseURL: "https://api.eco.digitalifts.com/api/"
});
apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = await authStorage.getToken();
    console.log('token', authToken);
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiClient;
