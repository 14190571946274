import {StyleSheet, Text, TouchableOpacity } from 'react-native';
import {useFonts} from 'expo-font';

import colors from '../config/colors';

function AppButton({title, onPress, styleText, styleButton, ...otherProps}) {

    const [loaded] = useFonts({
        'Montserrat': require('../assets/fonts/Montserrat-Regular.ttf'),
        'Montserrat Bold': require('../assets/fonts/Montserrat-Bold.ttf'),
    });
      
    if (!loaded) {
        return null;
    }

    return (
        <TouchableOpacity style={styleButton ? styleButton : styles.button} onPress={onPress} {...otherProps}>
                <Text style={styleText ? styleText : styles.text}>{title}</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    button: {
        
        backgroundColor: colors.green,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 15,
        paddingLeft: 15,
        width: '100%',
        color: colors.black,
        fontSize: 18,
        textTransform: 'uppercase',
        fontFamily: 'Montserrat Bold',
        marginTop: 20,
        borderRadius: 15
    },

    text: {
        textAlign: "center",
        color: colors.black,
        fontSize: 25,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: 'Montserrat Bold'
    }
})

export default AppButton;