export default {
    green: '#19ce77',
    black: "#18202c",
    white: "#d1d1d1",
    grey: "#868686",
    lightGrey: "#f2f2f2",
    chart: {
        lightGreen: '#69e56b',
        green: '#19ce77',
        darkGreen: '#0f7041'
    }
}