import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import React, { useEffect, useState, useContext } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

import authApi from '../api/auth';
import machineApi from '../api/machine';
import dataApi from '../api/data';
import AuthContext from '../auth/context';
import jwtDecode from 'jwt-decode';
import authStorage from '../auth/storage';
import { Button, Input, TextField } from '@mui/material';
import colors from '../../config/colors';
import { useSnackbar } from 'material-ui-snackbar-provider';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';
import { Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import PollIcon from '@mui/icons-material/Poll';
import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';

function DataScreen({ route, navigation }) {

  const theme = useTheme();
  const [colorScheme, setColorScheme] = React.useState('blueberryTwilight');
  const [colorMode, setColorMode] = React.useState("dark");

  const newTheme = createTheme({ palette: { mode: colorMode }, typography: {
    fontFamily: [
      'Montserrat Bold',
    ].join(','),
  }, });

    const forceUpdate = React.useReducer(() => ({}))[1]

    const isPortrait = () => {
        const dim = Dimensions.get('screen');
        return dim.height >= dim.width;
      };

    const snackbar = useSnackbar();

    const authContext = useContext(AuthContext);

    const [secured, setSecured] = useState(true);

    //Dates 
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  //

  const [dataColumns, setDataColumns] = useState([]);
  const [dataRows,setDataRows] = useState([]);

  const [graphDatas, setGraphDatas] = useState({
    "machine1": [], 
    "machine2": [], 
    "machine3": [],
    "dates": [],
});

  const years = [
    new Date(1990, 0, 1),
    new Date(1991, 0, 1),
    new Date(1992, 0, 1),
    new Date(1993, 0, 1),
    new Date(1994, 0, 1),
    new Date(1995, 0, 1),
    new Date(1996, 0, 1),
    new Date(1997, 0, 1),
    new Date(1998, 0, 1),
    new Date(1999, 0, 1),
    new Date(2000, 0, 1),
    new Date(2001, 0, 1),
    new Date(2002, 0, 1),
    new Date(2003, 0, 1),
    new Date(2004, 0, 1),
    new Date(2005, 0, 1),
    new Date(2006, 0, 1),
    new Date(2007, 0, 1),
    new Date(2008, 0, 1),
    new Date(2009, 0, 1),
    new Date(2010, 0, 1),
    new Date(2011, 0, 1),
    new Date(2012, 0, 1),
    new Date(2013, 0, 1),
    new Date(2014, 0, 1),
    new Date(2015, 0, 1),
    new Date(2016, 0, 1),
    new Date(2017, 0, 1),
    new Date(2018, 0, 1),
  ];
  
  const FranceGDPperCapita = [
    28129, 28294.264, 28619.805, 28336.16, 28907.977, 29418.863, 29736.645, 30341.807,
    31323.078, 32284.611, 33409.68, 33920.098, 34152.773, 34292.03, 35093.824,
    35495.465, 36166.16, 36845.684, 36761.793, 35534.926, 36086.727, 36691, 36571,
    36632, 36527, 36827, 37124, 37895, 38515.918,
  ];
  
  const UKGDPperCapita = [
    26189, 25792.014, 25790.186, 26349.342, 27277.543, 27861.215, 28472.248, 29259.764,
    30077.385, 30932.537, 31946.037, 32660.441, 33271.3, 34232.426, 34865.78,
    35623.625, 36214.07, 36816.676, 36264.79, 34402.36, 34754.473, 34971, 35185, 35618,
    36436, 36941, 37334, 37782.83, 38058.086,
  ];
  
  const GermanyGDPperCapita = [
    25391, 26769.96, 27385.055, 27250.701, 28140.057, 28868.945, 29349.982, 30186.945,
    31129.584, 32087.604, 33367.285, 34260.29, 34590.93, 34716.44, 35528.715,
    36205.574, 38014.137, 39752.207, 40715.434, 38962.938, 41109.582, 43189, 43320,
    43413, 43922, 44293, 44689, 45619.785, 46177.617,
  ];

  const [listMachines, setListMachines] = useState([]);
  const [machinesDatas, setMachinesDatas] = useState([]);

  const [chartView, setChartView] = useState(false);

  const getMachines = async () => {
    const result = await machineApi.getMachinesByUser();
    if(!result.ok) return snackbar.showMessage("Erreur lors de la récupération des informations machines.");
    setListMachines(result.data);
    getDatas(result.data);
  }


    const handleLogin = async () => {
        if ((username != "" && username != null) && (password != "" && password != null)) {
            console.log('values');
            console.log({ username, password });
            const result = await authApi.login(username, password);
            console.log(result.data);
            if (!result.ok) return snackbar.showMessage("Identifiants éronnés");
            const user = jwtDecode(result.data.token);
            authContext.setUser(result.data);
            authStorage.storeToken(result.data);
            console.log("RESULTAT");
            console.log(result.data);
            snackbar.showMessage("Connexion réussie");
        }
        else {
            snackbar.showMessage("Les champs ne peuvent pas être vides.");
        }
    }


    const getDatas = async (data) => {
      if(data != undefined && data != null)
      {
        var machines = data;
      }
      else var machines = listMachines;
      
      if(machines.length > 0)
      {
        var newMachinesDatas = [];
        var totalConsommationCapa = 0;
        var totalConsommationSans = 0;
        let finalObj = {
          "dates": [],
          "series": []
        };
        for(let i = 0; i < machines.length; i++)
        { 
          const result = await dataApi.getDatasByMachineForPeriod({machineId: machines[i].id, dateStart: startDate, dateEnd: endDate});
          if(machines[i].capacitor == 1) totalConsommationCapa += result.data.dataTotalFound.payload/1000;
          if(machines[i].capacitor == 0) totalConsommationSans += result.data.dataTotalFound.payload/1000;
        }
        for(let i = 0; i < machines.length; i++)
        {
          
          var newDatas = {datas: {totalPeriod: "Aucune consommation", totalPreviousPeriod: "Inconnu", total: "Aucune consommation", eco:"Inconnue"}};
          
          const result = await dataApi.getDatasByMachineForPeriod({machineId: machines[i].id, dateStart: startDate, dateEnd: endDate});
          newDatas.total = (result.data.dataTotalFound.payload/1000).toFixed(2);
          
          if(machines[i].capacitor == 1) newDatas.eco = (totalConsommationSans - newDatas.total).toFixed(2) + " kWh";
          if(result.data.dataStartFound != [])
          {
            newDatas.totalPeriod = ((result.data.dataStartFound[0].payload - result.data.dataEndFound[0].payload)/1000).toFixed(2) + " kWh";
          }
          else
          {
            newDatas.totalPeriod = "Aucune consommation";
          }
          if(result.data.lastPeriod.dataLastStartFound.length > 0)
          {
            newDatas.totalPreviousPeriod = ((result.data.lastPeriod.dataLastStartFound[0].payload - result.data.lastPeriod.dataLastEndFound[0].payload)/1000).toFixed(2) + " kWh";
          }
          else
          {
            newDatas.totalPreviousPeriod = "Aucune consommation";
          }
          

          machines[i].data = newDatas;

          let lastDate;
          let firstPayload;
          let lastPayload = null;
          console.log("RESULT");
          console.log(result);
          let thisSeries= {
            id: machines[i].address,
            label: "Consommation "+machines[i].address.replace("Belleville-en-Beaujolais","")+" (en wH)",
            data: [],
            area: true,
            color: i == 0 ? colors.chart.lightGreen : i == 1 ? colors.chart.green : colors.chart.darkGreen,
          }; 
          result.data.dataAllFound.forEach((dates, index) => {
              const date = dates.timestamp.split('T')[0];
              if(date != lastDate) 
              {
                  //On a une nouvelle date
                  //Si notre dernière date n'était pas NULL, on traite les données
                  console.log(date);
                  if(lastDate != null)
                  {
                      if(result.data.dataAllFound[index-1].payload !== undefined) lastPayload = result.data.dataAllFound[index-1].payload ;
                      else lastPayload = firstPayload;

                      thisSeries.data.push(lastPayload-firstPayload);
                      
                      if(i == 0) finalObj.dates.push(new Date(lastDate+"T00:00:00Z"));
                  }
                  lastDate = date; //Notre nouvelle date
                  firstPayload = dates.payload;
                  
              }
              if(index == result.data.dataAllFound.length-1)
              {
                  lastPayload = dates.payload;
                  thisSeries.data.push(lastPayload-firstPayload);
                  if(i == 0) finalObj.dates.push(new Date(lastDate+"T00:00:00Z"));
              }
              
          });
          finalObj.series.push(thisSeries);
          
        }
        console.log("FINAL OBJET");
        console.log(finalObj);
        setGraphDatas(finalObj);
        setMachinesDatas(machines);
        console.log(JSON.stringify(machines));
        forceUpdate();
      }
    }

    useEffect(() => {
      getMachines();
    }, [])
    
    useEffect(() => {
      getDatas()
    }, [startDate, endDate, listMachines])

    return (
      <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
      <View style={[styles.container, {padding: isPortrait() ? 0 : 40}]}>
        <Image source={require("../../assets/bg.png")} style={styles.backgroundImage}/>
        <View style={[styles.mainContainerFull, isPortrait() ? {width: "100%", height: "100%", justifyContent: "center", borderRadius: 0} : null]}>
          <Image source={require("../../assets/logo-greenride.png")} style={{width: 300, height:100, alignSelf: "center"}}  resizeMode="contain"/>
          <View style={[styles.dateSelector, isPortrait() ? {justifyContent: "center", flexDirection: "column", marginBottom: 30} : null]}>
            <Text style={[{fontFamily: "Montserrat Bold", fontSize: 25, color: colors.lightGrey, alignSelf: "center", marginRight: 20} , isPortrait() ? {marginBottom: 20} : null]}>Consommation du</Text>
            
            <DatePicker 
            InputLabelProps={{ shrink: true }}
            value={startDate}
            minDate={moment("01-06-2023", 'DD-MM-YYYY')}
            maxDate={endDate}
            onChange={(value) => setStartDate(value)}
            format="DD-MM-YYYY"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& svg': {
                  color: colors.green
                },
                '& fieldset': {
                  borderColor: colors.green,
                  borderWidth: 2,
                  color: colors.white,
                  borderRadius: 3
                },
                '&:hover fieldset': {
                  borderColor: colors.green,
                  borderWidth: 2,
                  color: colors.white
                },
                '&.Mui-focused fieldset': {
                  borderColor: colors.green,
                  borderWidth: 2,
                  color: colors.white
                },
                color: colors.white,
                fontFamily: "Montserrat Bold",
              },
            }} />
            <Text style={[{fontFamily: "Montserrat Bold", fontSize: 25, color: colors.lightGrey, alignSelf: "center", marginRight: 20, marginLeft: 20}, isPortrait() ? {marginBottom: 20, marginTop: 20} : null]}>au</Text>
            <DatePicker 
            InputLabelProps={{ shrink: true }}
            value={endDate}
            maxDate={moment().add(1,"day")}
            onChange={(value) => setEndDate(value)}
            format="DD-MM-YYYY"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& svg': {
                  color: colors.green
                },
                '& fieldset': {
                  borderColor: colors.green,
                  borderWidth: 2,
                  color: colors.white,
                  borderRadius: 3
                },
                '&:hover fieldset': {
                  borderColor: colors.green,
                  borderWidth: 2,
                  color: colors.white
                },
                '&.Mui-focused fieldset': {
                  borderColor: colors.green,
                  borderWidth: 2,
                  color: colors.white
                },
                color: colors.white,
                fontFamily: "Montserrat Bold",
              },
            }} />  
            <IconButton onClick={()=>setChartView(!chartView)} color="#19ce77" size="large" sx={{
              borderColor: colors.green,
              borderWidth: 2,
              borderRadius: 3, 
              marginLeft: 2,
            }}>
              <PollIcon  sx={{ 
                color: colors.green, 
                borderColor: colors.green,
                borderWidth: 2,
                borderRadius: 3,
              }}/>
            </IconButton>
          </View>
          
          
          <View style={[styles.screenContent, isPortrait() ? {flexDirection: "column", paddingLeft: 0, paddingRight: 0} : null]}>
            {listMachines.length > 0 && graphDatas.dates != [] ? (
              
                chartView == true ? <LineChart
                xAxis={[
                  {
                    id: 'Dates',
                    data: graphDatas.dates,
                    scaleType: 'time',
                    valueFormatter: (date) => date.toLocaleDateString("fr-FR", { // you can use undefined as first argument
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  },
                ]}
                yAxis={[
                  { id: 'linearAxis', scaleType: 'linear' },
                ]}
                /*series={[
                  {
                    id: 'France',
                    label: 'French GDP per capita',
                    data: FranceGDPperCapita,
                    stack: 'total',
                    area: true,
                    color: colors.chart.lightGreen,
                  },
                  {
                    id: 'Germany',
                    label: 'German GDP per capita',
                    data: GermanyGDPperCapita,
                    stack: 'total',
                    area: true,
                    color: colors.chart.green,
                  },
                  {
                    id: 'United Kingdom',
                    label: 'UK GDP per capita',
                    data: UKGDPperCapita,
                    stack: 'total',
                    area: true,
                    color: colors.chart.darkGreen,
                  },
                ]}*/
                series={graphDatas.series}
                sx={{
                  '--ChartsLegend-itemWidth': '400px',
                  '--ChartsLegend-fontSize': "10px",
                  '& .MuiChartsAxis-directionX .MuiChartsAxis-tickContainer':{
                    display: "none"
                  },
                  '& .MuiChartsAxis-directionX .MuiChartsAxis-tickContainer:last-of-type, .MuiChartsAxis-directionX .MuiChartsAxis-tickContainer:first-of-type':{
                    display: "inline"
                  },
                  '& .MuiLineElement-root': {
                    strokeDasharray: '10 5',
                    strokeWidth: 4,
                    strokeColor: "black"
                  },
                  '& .MuiMarkElement-root': {
                    display: 'none',
                  }
                }}
                width={1000}
                height={600}
                margin={{ left: 70 }}
              />   : (
                listMachines.map((item, key) => (
                  <View style={[styles.machineView, {borderColor: colors.green}, isPortrait() ? {width: "100%", marginBottom: 20} : null, item.capacitor == 1 ? null : {borderColor: colors.lightGrey, borderWidth: 1, shadowColor: "transparent"}]}>
                    <View style={[styles.machineInfos, item.capacitor == 1 ? null : {borderColor: colors.lightGrey}]}>
                      <Text style={styles.machineTitle}>{item.address}</Text>
                      <Text style={styles.machineSubTitle}>{item.capacitor == 1 ? "avec générateur "+item.additionalInfos: item.additionalInfos}</Text>
                      {
                        item.capacitor == 1 ? (
                          <Image source={require("../../assets/pile.gif")} style={[styles.machineIcon, isPortrait() ? {width: 50, height: 50, top: 30} : null]} resizeMode="contain"/>
                        ) : null
                      }
                      
                    </View>
                    {
                      startDate == null || endDate == null ? 
                      (
                        <View style={styles.machineContainer}>
                          <View style={styles.machineDatas}>
                            <Text style={styles.machineDatasSubTitle}>Veuillez selectionner une période...</Text> 
                            <Text style={styles.machineDatasTitle}> </Text>
                          </View>
                          <View style={styles.machineDatas}>
                            <Text style={styles.machineDatasSubTitle}> </Text> 
                            <Text style={styles.machineDatasTitle}> </Text>
                          </View>
                          <View style={styles.machineDatas}>
                            <Text style={styles.machineDatasSubTitle}> </Text> 
                            <Text style={styles.machineDatasTitle}> </Text>
                          </View>
                        </View>
                      )
                      :
                      (
                        <View style={styles.machineContainer}>
                          <View style={styles.machineDatas}>
                            <Text style={styles.machineDatasSubTitle}>Consommation pour la période</Text> 
                            <Text style={styles.machineDatasTitle}>{item.data === undefined ? "" : item.data.totalPeriod}</Text>
                          </View>
                          <View style={styles.machineDatas}>
                            <Text style={styles.machineDatasSubTitle}>Consommation période précédente</Text> 
                            <Text style={styles.machineDatasTitle}>{item.data === undefined ? "" : item.data.totalPreviousPeriod}</Text>
                          </View>
                          
                          <View style={styles.machineDatas}>
                            <Text style={styles.machineDatasSubTitle}>Consommation totale (depuis le 01/06/2023)</Text> 
                            <Text style={styles.machineDatasTitle}>{item.data === undefined ? "" : item.data.total + " kWh"} </Text>
                          </View>
                        </View>
                      )
                    }
                    
                  </View>
                
                )
              )
              
              )
            ) : (
              <View style={[styles.machineView, {borderColor: colors.green, flex:1, flexDirection: "row"}, isPortrait() ? {width: "100%", marginBottom: 20} : null]}>
                  <View style={styles.machineInfos}>
                    <Text style={styles.machineTitle}>Chargements des données...</Text>
                  </View>
                </View>
            )}
          </View>
          <View style={[styles.copyrights, isPortrait() ? {flexDirection: "column", paddingLeft: 0, paddingRight: 0} : null]}>
            <Text style={[{fontFamily: "Montserrat", color: colors.lightGrey}, isPortrait() ? {marginBottom: 20, textAlign: 'left'} : null]}>Les données sont données à titre indicatif | Ascenseurs Service en collaboration avec Sodimas, tous droits réservés </Text>
            <TouchableOpacity style={{cursor: "pointer"}} cursor="pointer" onPress={()=> Linking.openURL("http://ascenseurs-service.fr/")}>
              <Image source={require("../../assets/logo-as.png")} style={{width: 200, height:75, alignSelf: "flex-end"}} resizeMode="contain"/>
            </TouchableOpacity>
          </View>
        </View>
        <StatusBar style="auto" />
      </View>
    </LocalizationProvider>
    </ThemeProvider>
    );
}

export default DataScreen;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.green,
      alignItems: 'center',
      justifyContent: 'center',
    },
  
    mainContainer: {
      width: 480,
      backgroundColor: colors.black,
      padding: 30,
      borderRadius: 20
    },
  
    mainContainerFull: {
      flex: 1,
      width: "100%",
      backgroundColor: colors.black,
      padding: 30,
      borderRadius: 20,
      justifyContent: "space-between"
    },
    
    separator: {
      height: 2,
      backgroundColor: colors.lightGrey,
      width: "100%",
      marginTop: 40
    },
  
    backgroundImage: {
      position: 'absolute',
      width: "100%",
      height: "100%",
      opacity: 0.2,
      flex: 1
    },
  
    dateSelector: {
      flexDirection: "row",
      justifyContent: "center"
    },
  
    machineView: {
      borderWidth: 4,
      borderRadius: 20,
      width: "30%",
      shadowColor: colors.green,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
  
      elevation: 6,
    },
  
    machineContainer: {
      padding: 30
    },
  
    machineDatas: {
      marginTop: 20,
      textAlign: "center"
    },
  
    machineInfos: {
      borderBottomWidth: 2,
      borderBottomColor: colors.green,
      padding: 30
    },
  
    machineIcon: {
      position: "absolute",
      width: 75,
      height: 75,
      top: 20,
      right: 30
    },
  
    machineTitle: {
      fontFamily: "Montserrat Bold", 
      fontSize: 25,
      marginRight: 100,
      color: colors.lightGrey,
    },
  
    machineSubTitle: {
      fontFamily: "Montserrat", 
      fontSize: 18, 
      color: colors.lightGrey,
    },
  
    machineDatasTitle: {
      fontFamily: "Montserrat Bold", 
      fontSize: 25, 
      color: colors.lightGrey,
    },
  
    machineDatasSubTitle: {
      fontFamily: "Montserrat", 
      fontSize: 18, 
      color: colors.lightGrey,
    },
  
    screenContent: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 50,
      paddingRight: 50,
    },
    
    copyrights: {
      borderTopWidth: 2,
      borderTopColor: colors.lightGrey,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 20,
      alignSelf: "flex-end",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%"
    }
  
  
  });
  