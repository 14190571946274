import { StatusBar } from 'expo-status-bar';
import { useState, useContext, useEffect } from 'react';
import { Button, Image, Linking, StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import {useFonts} from 'expo-font';
import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LoginScreen from './app/screens/LoginScreen';
import DataScreen from './app/screens/DataScreen';
import moment from 'moment';
import AppButton from './components/AppButton';
import AppInput from './components/AppInput';
import colors from './config/colors'; 
import authApi from './app/api/auth';
import AuthContext from './app/auth/context'; 
import jwtDecode from 'jwt-decode';
import authStorage from './app/auth/storage';
import { useSnackbar, SnackbarProvider } from 'material-ui-snackbar-provider';
  
  
export default function App() {

  moment.locale('fr');

  const snackbar = useSnackbar();
  const isPortrait = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
  };
  const [logged, setLogged] = useState(false);

  const authContext = useContext(AuthContext);

  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
      if ((username != "" && username != null) && (password != "" && password != null)) {
          console.log('values');
          console.log({ username, password });
          const result = await authApi.login(username, password);
          console.log(result.data);
          if (!result.ok) return snackbar.showMessage("Identifiants érronés");
          const user = jwtDecode(result.data.token);
          authContext.setUser(result.data);
          authStorage.storeToken(result.data);
          console.log("RESULTAT");
          console.log(result.data);
          setLogged(true);
      }
      else {
        snackbar.showMessage("Les champs ne peuvent pas être vides.");
      }
  }


  
  const [user, setUser] = useState();

  const restoreUser = async () => {
    const thisUser = await authStorage.getUser();

    if (!thisUser) return;
    setUser(thisUser);
    console.log('user:' + thisUser);
    console.log(thisUser);

  };

  useEffect(() => {
    //requestPermission();
    restoreUser();

  }, [])

  const [loaded] = useFonts({
    'Montserrat': require('./assets/fonts/Montserrat-Regular.ttf'),
    'Montserrat Bold': require('./assets/fonts/Montserrat-Bold.ttf'),
  });
    
  if (!loaded) {
      return null;
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
    <SnackbarProvider SnackbarProps={{
      autoHideDuration: 10000, anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      }, bodyStyle: { backgroundColor: "red" }
    }}>
      <LocalizationProvider locale={"fr"} dateAdapter={AdapterMoment}>

      {user ? 
        <DataScreen />
      : <LoginScreen/>}
      
      </LocalizationProvider>
    </SnackbarProvider>
    </AuthContext.Provider>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.green,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mainContainer: {
    width: 480,
    backgroundColor: colors.black,
    padding: 30,
    borderRadius: 20
  },

  mainContainerFull: {
    flex: 1,
    width: "100%",
    backgroundColor: colors.black,
    padding: 30,
    borderRadius: 20,
    justifyContent: "space-between"
  },
  
  separator: {
    height: 2,
    backgroundColor: colors.lightGrey,
    width: "100%",
    marginTop: 40
  },

  backgroundImage: {
    position: 'absolute',
    width: "100%",
    height: "100%",
    opacity: 0.2,
    flex: 1
  },

  dateSelector: {
    flexDirection: "row",
    justifyContent: "center"
  },

  machineView: {
    borderWidth: 4,
    borderRadius: 20,
    width: "30%",
    shadowColor: colors.green,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,

    elevation: 6,
  },

  machineContainer: {
    padding: 30
  },

  machineDatas: {
    marginTop: 20,
    textAlign: "center"
  },

  machineInfos: {
    borderBottomWidth: 2,
    borderBottomColor: colors.green,
    padding: 30
  },

  machineIcon: {
    position: "absolute",
    width: 75,
    height: 75,
    top: 20,
    right: 30
  },

  machineTitle: {
    fontFamily: "Montserrat Bold", 
    fontSize: 25,
    marginRight: 100,
    color: colors.lightGrey,
  },

  machineSubTitle: {
    fontFamily: "Montserrat", 
    fontSize: 18, 
    color: colors.lightGrey,
  },

  machineDatasTitle: {
    fontFamily: "Montserrat Bold", 
    fontSize: 25, 
    color: colors.lightGrey,
  },

  machineDatasSubTitle: {
    fontFamily: "Montserrat", 
    fontSize: 18, 
    color: colors.lightGrey,
  },

  screenContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
  },
  
  copyrights: {
    borderTopWidth: 2,
    borderTopColor: colors.lightGrey,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%"
  }


});
